@import '~bootstrap/scss/_mixins';
@import 'Variables.scss';
@import '~bootstrap/scss/bootstrap';

html {
	scroll-behavior: smooth;
}

body {
	font-family: 'Roboto Condensed', sans-serif;
}

a:link,
a:visited,
a:hover,
a:active {
	transition: 0.5s;
	text-decoration: none !important;
	color: #b17f4c !important;
}

a:hover {
	color: #e3b381 !important;
}

p {
	font-size: 1.2em;
	line-height: 1.8em;
	margin-bottom: 0px;
	color: #333;
}

h1 {
	font-family: 'Bodoni Moda', serif;
	letter-spacing: 2px;
	font-weight: 200;
	color: #b17f4c;
	font-size: 2.8em;
	text-transform: uppercase;
}

h2 {
	font-family: 'Bodoni Moda', serif;
	text-transform: uppercase;
	color: #666;
	margin-top: 20px;
	margin-bottom: 20px;
}

h3 {
	font-family: 'Playfair Display', serif;
	text-transform: uppercase;
	font-weight: 800;
	font-size: 1.2em;
}

hr {
	border: 1px solid grey;
	width: 100%;
}

.btn:active,
.btn:focus,
.btn.active {
	background-image: none;
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.navigation {
	scroll-margin-top: 71.9px;
}

.navbar {
	font-size: 1.2em;
	background-color: rgba(255, 255, 255, 0.9);
}

.nav-link {
	padding-top: 15px;
	padding-bottom: 15px;
}

.navigation-first {
	position: absolute;
	z-index: 1030;
	top: 410px;
	right: 0;
	left: 0;
}

.navigation-second {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}

@media (max-width: 992px) {
	.navigation-first {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		z-index: 1030;
	}

	.navigation-second {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		z-index: 1030;
	}
	.navigation {
		scroll-margin-top: 56px;
	}
}

@media (max-width: 576px) {
	h1 {
		font-size: 2em;
	}
}

.component {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px;
	padding-top: 40px;
	padding-bottom: 50px;
	text-align: center;
}

.container {
	max-width: 600px;
}

.component-start {
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0px;
	padding-top: 40px;
	padding-bottom: 20px;
	text-align: center;
}

.component-location {
	background-color: #ededed;
}

.component-timeline {
	background-color: white;
}

.component-informations {
	background-color: #ededed;
}

.component-registration {
	background-color: white;
}

.component-contact {
	color: white;
	background-color: #444;
}

.component-footer {
	background-color: black;
}

.nav-link {
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 0.9em;
}

.standing-data-list-overlay {
	position: fixed;
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
	z-index: 200000;
	background-color: rgba(0, 0, 0, 0.5);
}

.standing-data-list-overlay #background {
	max-height: 100%;
	border-radius: 10px;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}

.standing-data-list-overlay #menuebar {
	border-radius: 10px 10px 0 0;
	//background-color: $primary;
	background-color: white;
	padding: 5px;
	padding-right: 10px;
	text-align: right;
	color: white;
}

.standing-data-list-overlay #main-window {
	background-color: white;
	border-radius: 0 0 10px 10px;
	overflow-y: auto;
	max-height: 80vh;
	padding: 20px;
	padding-bottom: 25px;
}

.standing-data-list-overlay #icon-close {
	cursor: pointer;
}

@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css');
