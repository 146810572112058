.color-gold {
	color: #b17f4c;
}

.color-white {
	color: white;
}

.color-grey {
	color: #212529;
}

.color-dark-grey {
	color: #444;
}
