@keyframes move-up {
	from {
		padding-top: 40px;
	}
	to {
		padding-top: 0px;
	}
}

@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.imgRingContainer {
	position: relative;
	height: 80px;
	width: 160px;
	margin-top: 10px;
}

.imgRing {
	animation: move-up 2s;
	padding-top: 0px;
}

.line {
	width: 135px;
	height: 30px;
	margin-top: -25px;
	margin-bottom: -30px;
	z-index: 4;
	background-color: white;
	border-top: 1px solid #b17f4c;
}

.date {
	font-family: 'Roboto Condensed', sans-serif;
	font-weight: 400;
	font-size: 2em;
	color: #b17f4c;
	z-index: 5;
	margin-bottom: 23px;
}

.names {
	font-family: 'Bodoni Moda', serif;
	text-transform: uppercase;
	letter-spacing: 3px;
	color: grey;
	font-size: 4em;
}

.and {
	font-family: 'Bodoni Moda', serif;
	color: grey;
	font-size: 3em;
	margin-bottom: -20px;
	margin-top: -24px;
}

.imgPairContainer {
	animation: fade-in 5s;
	opacity: 1;
	padding-top: 0px;
	margin-top: 5.5em;
	max-width: 1700px;
	position: relative;
	height: 600px;
	width: 100%;
	object-fit: cover;
	object-position: left top;
}

.slogan {
	margin-top: 20px;
	font-size: 1.2em;
	color: #b17f4c;
	font-weight: bold;
	text-transform: uppercase;
}

@media (max-width: 576px) {
	.and {
		font-size: 2em;
		margin-bottom: -10px;
		margin-top: -10px;
	}

	.names {
		color: grey;
		font-size: 2.5em;
	}
}

@media (max-width: 992px) {
	.imgRingContainer {
		margin-top: 25px;
	}

	.imgPairContainer {
		margin-top: 3.5em;
		position: relative;
		height: 400px;
		width: 100%;
	}
}
