.html {
	padding-bottom: 50px;
}

.body {
	background-color: black;
	min-height: 100vh;
	padding-bottom: 50px;
}

.gold {
	color: #b17f4c;
}

.white {
	color: white;
}

.save-the-date {
	opacity: 0;
	animation: fade-in 4s linear forwards;
	animation-delay: 8s;
	font-family: 'Playfair Display', serif;
	margin-top: 10px;
	color: #b17f4c;
	font-size: 3em;
}

.save-the-date::after {
	opacity: 1;
}

.date {
	opacity: 0;
	animation: fade-in 4s linear forwards;
	animation-delay: 9s;
	font-family: 'Playfair Display', serif;
	margin-top: -7px;
	color: white;
	font-weight: 800;
	font-size: 2.3em;
}

.names {
	opacity: 0;
	animation: fade-in 4s linear forwards;
	animation-delay: 10s;
	font-family: 'Playfair Display', serif;
	font-size: 1.7em;
	font-weight: 200;
	letter-spacing: 0.09em;
	color: #b17f4c;
}

.extended-infos {
	opacity: 0;
	animation: fade-in 4s linear forwards;
	animation-delay: 11s;
	font-family: 'Playfair Display', serif;
	font-style: italic;
	margin-top: 16px;
	line-height: 30px;
	font-size: 1.2em;
	letter-spacing: 0.02em;
	color: white;
}

.blue,
.purple,
.green,
.background {
	/*opacity: 0.8;*/
	stroke-dasharray: 2000;
	animation: flaeche 4s linear;
	animation-direction: alternate;
	/*animation-iteration-count: infinite;*/
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-width: 0;
	stroke-opacity: 1;
	stroke: #fff;
	stroke-linecap: round;
}

.basic {
	stroke-dasharray: 2000;
	animation-direction: alternate;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-width: 0;
	stroke-opacity: 1;
	stroke: #fff;
	stroke-linecap: round;
}

.inner-circle {
	animation: inner-circle 4s linear;
}

.outer-circle {
	animation: outer-circle 4s linear;
}

.c-letter {
	animation: n-letter 8s linear;
}

.n-letter {
	animation: n-letter 8s linear;
}

.glamour {
	transform-origin: center;
	stroke-dasharray: 2000;
	animation: glamour 8s linear;
	animation-direction: alternate;
	/*animation-iteration-count: infinite;*/
}

.kringel {
	stroke-dasharray: 2000;
	animation: kringel 4s linear;
	animation-direction: alternate;
	/*animation-iteration-count: infinite;*/
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-width: 0;
	stroke-opacity: 1;
	stroke: #fff;
	stroke-linecap: round;
}

.hr {
	border: 1px solid white;
	margin: auto;
	margin-top: 1rem;
	margin-bottom: 1rem;
	animation: hr-animation 8s linear;
	opacity: 0.4;
	width: 345px;
}

@keyframes hr-animation {
	0% {
		opacity: 0;
		width: 0px;
	}
	90% {
		opacity: 0;
		width: 0px;
	}
	100% {
		opacity: 1;
		width: 345px;
	}
}

@keyframes n-letter {
	0% {
		stroke-dashoffset: 2000;
		stroke-width: 4;
		fill-opacity: 0;
		stroke-opacity: 0;
	}
	50% {
		stroke-dashoffset: 2000;
		stroke-width: 4;
		fill-opacity: 0;
		stroke-opacity: 0;
	}
	60% {
		fill-opacity: 0;
		stroke-opacity: 1;
	}
	80% {
		stroke-dashoffset: 0;
		stroke-width: 0;
		stroke-opacity: 0;
	}
	100% {
		stroke-dashoffset: 0;
		stroke-opacity: 0;
		fill-opacity: 1;
		stroke-width: 0;
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes kringel {
	0% {
		stroke-dashoffset: 2000;
		stroke-width: 4;
		fill-opacity: 0;
		stroke-opacity: 0;
	}
	50% {
		stroke-dashoffset: 2000;
		stroke-width: 4;
		fill-opacity: 0;
		stroke-opacity: 1;
	}
	60% {
		fill-opacity: 0;
	}
	80% {
		stroke-dashoffset: 0;
		stroke-width: 0;
		stroke-opacity: 0;
	}
	100% {
		stroke-dashoffset: 0;
		stroke-opacity: 0;
		fill-opacity: 1;
		stroke-width: 0;
	}
}

@keyframes glamour {
	0% {
		fill-opacity: 0;
		transform: rotate(200deg) scaleX(0.1) scaleY(0.1);
	}
	80% {
		fill-opacity: 0;
		transform: rotate(600deg) scaleX(0.1) scaleY(0.1);
	}
	100% {
		fill-opacity: 0.8;
		transform: rotate(0deg) scaleX(1) scaleY(1);
	}
}

@keyframes outer-circle {
	0% {
		stroke-dashoffset: 2000;
		stroke-width: 4;
		fill-opacity: 0;
		stroke-opacity: 0;
	}
	20% {
		stroke-dashoffset: 2000;
		stroke-width: 4;
		stroke-opacity: 1;
	}
	80% {
		stroke-dashoffset: 0;
		stroke-width: 0;
		stroke-opacity: 1;
	}
	90% {
		stroke-dashoffset: 0;
		stroke-opacity: 0;
		fill-opacity: 1;
		stroke-width: 0;
	}
}

@keyframes inner-circle {
	0% {
		stroke-dashoffset: 2000;
		stroke-width: 4;
		fill-opacity: 0;
		stroke-opacity: 0;
	}
	20% {
		stroke-dashoffset: 2000;
		stroke-width: 4;
		fill-opacity: 0;
		stroke-opacity: 1;
	}
	30% {
		fill-opacity: 0;
	}
	50% {
		stroke-dashoffset: 0;
		stroke-width: 0;
		stroke-opacity: 0;
	}
	70% {
		stroke-dashoffset: 0;
		stroke-opacity: 0;
		fill-opacity: 1;
		stroke-width: 0;
	}
}

@keyframes flaeche {
	0% {
		stroke-dashoffset: 2000;
		stroke-width: 4;
		fill-opacity: 0;
		stroke-opacity: 0;
	}
	20% {
		stroke-dashoffset: 2000;
		stroke-width: 4;
		fill-opacity: 0;
		stroke-opacity: 1;
	}
	30% {
		fill-opacity: 0;
	}
	50% {
		stroke-dashoffset: 0;
		stroke-width: 0;
		stroke-opacity: 0;
	}
	70% {
		stroke-dashoffset: 0;
		stroke-opacity: 0;
		fill-opacity: 1;
		stroke-width: 0;
	}
}

.multiply {
	mix-blend-mode: multiply;
}
